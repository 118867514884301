import { useRef, useEffect } from "react"
import { QRCodeSVG } from "qrcode.react"
import { useAppDispatch } from "@store/store"
import { setQrCodeData } from "@store/slices/pdfViewer/pdfViewer"
import { QRCodeGenerateProps } from "./QRCodeGenerate.types"
import { DEFAULT_QRCODE_WIDTH } from "../PragmaPdfViewer/components/StampHandler/utils"


export const QRCodeGenerate = ({ value }: QRCodeGenerateProps) => {
  const dispatch = useAppDispatch()
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {

    if (containerRef.current) {
      const svgElement = containerRef.current.querySelector("svg")
      if (svgElement) {
        const svgString = new XMLSerializer().serializeToString(svgElement)
        const svgDataUrl = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgString)}`
        dispatch(setQrCodeData(svgDataUrl))
      }
    }
  }, [containerRef.current])

  return (
    <div ref={containerRef} style={{
      display: 'flex',
      backgroundColor: '#fff',
      width: '296px',
      height: '116px',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <QRCodeSVG
        value={value}
        title={value}
        size={117}
        marginSize={3}
        bgColor='#fff'
        fgColor="#000"
        level="L"
      /*       imageSettings={{
              src: "https://",
              x: undefined,
              y: undefined,
              height: 24,
              width: 24,
              opacity: 1,
              excavate: true,
            }} */
      />
    </div>
  )
}

/* export const useQRCodeGenerate = ({ value }: { value: string }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [jpegUrl, setJpegUrl] = useState<string | null>(null);

  useEffect(() => {
    const container = containerRef.current
    if (container) {
      const qrCanvas = container.querySelector(".qrcode-react") as HTMLCanvasElement
      if (qrCanvas) {
        const jpegDataUrl = qrCanvas.toDataURL("image/jpeg");
        setJpegUrl(jpegDataUrl)
      }
    }
  }, [])

  const qrCodeElement = (
    <div ref={containerRef}>
      <QRCodeCanvas
        className="qrcode-react"
        value={value}
        size={128}
        bgColor="#ffffff"
        fgColor="#000000"
        level="L"
              imageSettings={{
          src: "https://static.zpao.com/favicon.png",
          x: undefined,
          y: undefined,
          height: 24,
          width: 24,
          opacity: 1,
          excavate: true,
        }}
      />
    </div>
  )

  return { qrCodeElement, jpegUrl }


  <canvas ref={canvasRef} width={128} height={128} style={{ display: "none" }} />

} */
