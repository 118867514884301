export const DEFAULT_STAMP_WIDTH = 150
export const DEFAULT_STAMP_HEIGHT = 61.5
export const DEFAULT_STAMP_ASPECTRATIO = DEFAULT_STAMP_HEIGHT / DEFAULT_STAMP_WIDTH
export const DEFAULT_QRCODE_WIDTH = 56
export const DEFAULT_QRCODE_HEIGHT = 56
export const DEFAULT_QRCODE_ASPECTRATIO = DEFAULT_QRCODE_HEIGHT / DEFAULT_QRCODE_WIDTH


export const svgToJpg = async (svgData: string, scale: number = 2): Promise<string | Uint8Array | ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      if (!ctx) {
        reject(new Error('Не удалось конвертировать svg'))
        return
      }

      canvas.width = img.width * scale
      canvas.height = img.height * scale

      ctx.fillStyle = "transparent" /* "white" */
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      ctx.scale(scale, scale)
      ctx.drawImage(img, 0, 0)

      canvas.toBlob(
        (blob) => {
          if (blob) {
            const reader = new FileReader()
            reader.onloadend = () => resolve(new Uint8Array(reader.result as ArrayBuffer))
            reader.onerror = reject
            reader.readAsArrayBuffer(blob)
          } else {
            reject(new Error('Не удалось конвертировать svg'))
          }
        },
        'image/png',/* 'image/jpeg' */
        1.0
      )
    }

    img.onerror = reject
    img.src = svgData
  })
}