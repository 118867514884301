import { UserCompanyInfo } from "@api/users/types";
import { CustomStamp } from "@pages/DocView/components/StampRightSideBar";
import { profileSelector } from "@store/slices/profile";
import { useTypedSelector } from "@store/store";
import { format as FnsFormat } from 'date-fns'
import { AllStamps } from ".";

interface GetStampDataProps {
  type: string
  company: UserCompanyInfo
  firstName: string
  lastName: string
  middleName: string
  currentDate: string
}

const getStampData = (props: GetStampDataProps): {
  image: HTMLImageElement
  data: string
} => {
  const { company, currentDate, firstName, lastName, middleName, type } = props
  const img = new Image()
  const data = CustomStamp({
    date: currentDate,
    type,
    company: company.userCompanyName,
    position: company.userPosition,
    name: `${lastName} ${firstName[0]}. ${middleName ? middleName[0] + '.' : ''}`
  })
  img.src = data
  return { image: img, data }
}

export const useCustomStamp = () => {
  const currentDate = FnsFormat(new Date(), 'dd.MM.yyyy')
  const { company, firstName, lastName, middleName } = useTypedSelector(profileSelector)

  const allStamps: AllStamps = {
    'agreed': {
      name: 'Штамп Согласовано',
      image: getStampData({ company, currentDate, firstName, lastName, middleName, type: 'Согласовано' }).image,
      data: getStampData({ company, currentDate, firstName, lastName, middleName, type: 'Согласовано' }).data
    },
    'approved': {
      name: 'Штамп Утверждено',
      image: getStampData({ company, currentDate, firstName, lastName, middleName, type: 'Утверждено' }).image,
      data: getStampData({ company, currentDate, firstName, lastName, middleName, type: 'Утверждено' }).data
    },
    'inProd': {
      name: 'Штамп В производство работ',
      image: getStampData({ company, currentDate, firstName, lastName, middleName, type: 'В производство работ' }).image,
      data: getStampData({ company, currentDate, firstName, lastName, middleName, type: 'В производство работ' }).data
    },
  }

  return { allStamps }

}