import { SideBarWrapper, StampButton, StyledToggleButton, StyledToggleButtonGroup } from "./StampRightSideBar.styles"
import { CustomStamp } from "./config"
import { format as FnsFormat } from 'date-fns'
import { useAppDispatch, useTypedSelector } from "@store/store"
import { setIsSelectedQRCode, setSelectedStamp, setStampMode } from "@store/slices/pdfViewer/pdfViewer"
import { DefaultStamp, StampMode, StampTypes } from "@store/slices/pdfViewer/pdfViewer.types"
import { isSelectedQRCodeSelector, qrCodesSelector, selectedStampSelector, stampModeSelector, stampsSelector } from "@store/slices/pdfViewer/selectors/pdfViewer.selectors"
import Button from "@components/Button"
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Stack } from "@mui/material"
import { StampRightSideBarProps } from "./StampRightSideBar.types"
import { QRCodeGenerate } from "../QRCodeGenerate"
import { useMemo } from "react"
import { config } from "@core/config"
import { projectPhaseRuShortToEnShort } from "@typesGlobal/project"

const currentDate = FnsFormat(new Date(), 'dd.MM.yyyy')
const defaultStamp: DefaultStamp[] = [
  {
    type: 'agreed',
    data: CustomStamp({ date: currentDate, type: 'Согласовано' }),
    name: 'Штамп Согласовано'
  },
  {
    type: 'approved',
    data: CustomStamp({ date: currentDate, type: 'Утверждено' }),
    name: 'Штамп Утверждено'
  },
  {
    type: 'inProd',
    data: CustomStamp({ date: currentDate, type: 'В производство работ' }),
    name: 'Штамп В производство работ'
  },
]

export const StampRightSideBar = ({
  docId,
  type,
  handleCancel,
  handleSave,
  handleChangeStampMode
}: StampRightSideBarProps) => {
  const dispatch = useAppDispatch()
  const selectedStamp = useTypedSelector(selectedStampSelector)
  const qrCodes = useTypedSelector(qrCodesSelector)
  const stamps = useTypedSelector(stampsSelector)
  const stampMode = useTypedSelector(stampModeSelector)
  const selectedQrCode = useTypedSelector(isSelectedQRCodeSelector)

  const link = useMemo(() => `https://${config.appHost}/qr/${docId}?phase=${projectPhaseRuShortToEnShort[type]}`, [docId, type])
  
  const handleStampClick = (type: StampTypes) => {
    dispatch(setSelectedStamp({ isActive: true, type }))
  }

  const handleQRCodeClick = () => {
    dispatch(setIsSelectedQRCode(true))
  }

  return (
    <SideBarWrapper direction='column'>
      <Button icon color='primary' sx={{ gap: 1, alignSelf: 'flex-start', mb: 1.25 }}
        variant='text' onClick={() => handleCancel({})}>
        <ArrowBackIcon />
        Назад
      </Button>

      <StyledToggleButtonGroup
        value={stampMode}
        exclusive
        color='primary'
        onChange={(e, value) => handleChangeStampMode(value)}
      >
        <StyledToggleButton value='stamp' >штампы</StyledToggleButton>
        <StyledToggleButton value='qrcode' >qr-коды</StyledToggleButton>
      </StyledToggleButtonGroup>

      <Stack direction='column' spacing={1}>
        {stampMode === 'stamp' ?
          defaultStamp.filter(s => selectedStamp !== null ? selectedStamp.type === s.type : s).map((stamp) =>
            <StampButton onClick={() => handleStampClick(stamp.type)} key={stamp.type} border={!!selectedStamp?.isActive}>
              <img src={stamp.data} width='100%' alt={stamp.name} />
            </StampButton>
          ) :
          <StampButton onClick={handleQRCodeClick} border={selectedQrCode}>
            <QRCodeGenerate value={link} />
          </StampButton>
        }

      </Stack>
      {(selectedStamp !== null || stampMode === 'qrcode') &&
        <Button fullWidth color='success'
          disabled={stampMode === 'stamp' ? stamps.length === 0 : qrCodes.length === 0}
          sx={{ mt: 'auto' }} onClick={handleSave}
        >Сохранить
        </Button>
      }
    </SideBarWrapper>
  )
}