import { api } from "../api"
import { GetQRCodeDocInfoResponse, GetQRCodeDocInfoRequest } from "./api.types"

export const qrCodeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getQRCodeDocInfo: build.query<GetQRCodeDocInfoResponse, GetQRCodeDocInfoRequest>({
      query: ({ docId, type }) => ({
        url: `/qr/doc/${docId}`,
        method: 'GET',
        params: { type }
      }),
    }),
  }),
  overrideExisting: false,
})

export const { useGetQRCodeDocInfoQuery } = qrCodeApi