export interface ConfigItem {
  companyName: string
  appHost: string
  apiHost: string

  coreURL?: string
}

export interface Config extends ConfigItem {
  coreURL: string
}

export type CompanyName =
  | 'step'
  | 'nn'
  | 'vls'
  | 'gm'
  | 'kfha'
  | 'pyrus'
  | 'nlmk'
  | 'oes'
  | 'most'
  | 'eks'
  | 'ap'
  | 'ig'
  | 'energomost'
  | 'sti'
  | 'rzd'
  | 'ecolant'
  | 'zs'
  | 'cg'
  | 'fd'
  | 'rgs'
  | 'sp'
  | 'geo'
  | 'gcsu-555'
  | 'su555'
  | 'rusteko'
  | 'dml'
  | 'kvant'
  | 'krno'
  | 'er'
  | 'tg'
  | 'rsk'
  | 'blacksea'
  | 'samolet'
  | 'inarctica'
  | 'buildservice'
  | 'pelisker'
  | 'ezois-ural'
  | 'soyuzdonstroy'
  | 'gsp'
  | 'setlgroup'
  | 'psm'
  | 'vgtu'
  | 'tgasu'

export const companyNames: CompanyName[] = [
  'step',
  'nn',
  'vls',
  'gm',
  'kfha',
  'pyrus',
  'nlmk',
  'oes',
  'most',
  'eks',
  'ap',
  'ig',
  'energomost',
  'sti',
  'rzd',
  'ecolant',
  'zs',
  'cg',
  'fd',
  'rgs',
  'sp',
  'geo',
  'gcsu-555',
  'su555',
  'rusteko',
  'dml',
  'kvant',
  'krno',
  'er',
  'tg',
  'rsk',
  'blacksea',
  'samolet',
  'inarctica',
  'buildservice',
  'pelisker',
  'ezois-ural',
  'soyuzdonstroy',
  'gsp',
  'setlgroup',
  'psm',
  'vgtu',
  'tgasu',
]

export const isCompany = (companyName: any): companyName is CompanyName => {
  return companyNames.includes(companyName)
}
