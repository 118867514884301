import { Box, Stack, Typography } from "@mui/material";
import styled from "styled-components";
import { DocColorStatus, DocStatus } from ".";
import { ReactComponent as DesignIcon } from '@assets/design-logo.svg'

export const QRWrapper = styled(Stack) <{ actual: DocStatus }>`
  position: absolute;
  height: 65vh;
  width: 100%;
  top: 0;
  left: 0;
  background: ${props => props.actual === 'actual' && 'linear-gradient(#14B8A6, #EDEFF3)'};
  background: ${props => props.actual === 'actualWithChanges' && 'linear-gradient(#FF9B44, #EDEFF3)'};
  background: ${props => props.actual === 'notActual' && 'linear-gradient(#F46C6C, #EDEFF3)'};
`

export const StatusSection = styled(Stack)`
  position: relative;
  width: 100%;
  padding-right: 16px;
  min-height: 40vh;
  justify-content: center;
  align-items: center;
  z-index: 1;
`

export const LogoWrapper = styled(Box)`
  position: absolute;
  width: 100%;
  top: 3vh;
`

export const DesignLogo = styled(DesignIcon)`
  width: 160px;
  height: 64px;
`

export const TomStatusText = styled(Typography)`
  font-size: 20px;
  font-weight: 600;
  color: ${props => props.theme.palette.text.light};
`

export const WithChangeLabel = styled(Stack)`
  width: 174px;
  height: 29px;
  margin-top: 8px;
  border-radius: 16px;
  justify-content: center;
  background-color: ${props => props.theme.palette.warning.main};

  & .MuiTypography-root {
    color: ${props => props.theme.palette.text.light};
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
  }
`

export const TomSection = styled(Stack)`
  width: 100%;
  max-width: 800px;
  text-align: start;
  z-index: 1;
  overflow-x: auto;
  padding-right: 16px;

  &::-webkit-scrollbar {
    width: 10px;
  }

`

export const BlockWrapper = styled(Stack)`
  background-color: ${props => props.theme.palette.bg.white};
  border-radius: 8px;
  padding: 10px;
`
export const ColorStatus = styled(Stack) <{ status: DocColorStatus }>`
  border-radius: 4px;
  height: 21px;
  justify-content: center;
  color: ${props => props.theme.palette.text.light};
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: ${props => props.status === 'Актуальна' && props.theme.palette.legends.lightgreen};
  background-color: ${props => (props.status === 'Неактуальна' || props.status === 'Аннулирован') && props.theme.palette.legends.error};
  background-color: ${props => props.status === 'В ПРОИЗВОДСТВЕ' && props.theme.palette.legends.darkLime};
  background-color: ${props => props.status === 'СОГЛАСОВАНО' && props.theme.palette.legends.lightgreen};
  background-color: ${props => props.status === 'КОРРЕКТИРОВКА' && props.theme.palette.legends.red};
  background-color: ${props => props.status === 'РАЗРАБОТКА' && props.theme.palette.legends.blue};
  background-color: ${props => props.status === 'НЕ В РАБОТЕ' && props.theme.palette.legends.disabled};
  background-color: ${props => props.status === 'НА СОГЛАСОВАНИИ' && props.theme.palette.legends.yellow};
  background-color: ${props => props.status === 'НЕ СОГЛАСУЕТСЯ' && props.theme.palette.legends.darkPink};
`
export const TextData = styled(Typography)`
  color: ${props => props.theme.palette.disabled};
  font-size: 16px;
`