import { useCallback, useEffect, useMemo, useState } from "react";
import { StampHandlerProps } from "./StampHandler.types";
import { LoadingCube, LoadingText, StampPreview } from "./StampHandler.styles";
import { useAppDispatch, useTypedSelector } from "@store/store";
import { setIsSelectedQRCode, setQRCodes, setSaveTrigger, setSelectedStamp, setStampMode, setStamps } from "@store/slices/pdfViewer/pdfViewer";
import { useCustomStamp } from "./useCustomStamp";
import { isSelectedQRCodeSelector, qrCodeDataSelector, qrCodesSelector, saveTriggerSelector, stampModeSelector, stampsSelector } from "@store/slices/pdfViewer/selectors/pdfViewer.selectors";
import { pdfjs } from "react-pdf";
import { degrees, PDFDocument } from "pdf-lib";
import { DEFAULT_QRCODE_ASPECTRATIO, DEFAULT_QRCODE_HEIGHT, DEFAULT_QRCODE_WIDTH, DEFAULT_STAMP_ASPECTRATIO, DEFAULT_STAMP_HEIGHT, DEFAULT_STAMP_WIDTH, svgToJpg } from "./utils";
import { Stamp } from "@store/slices/pdfViewer/pdfViewer.types";
import { Backdrop, Stack } from "@mui/material";

const handlePoint = new Image()
handlePoint.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(`
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="3" width="24" height="24" rx="12" fill="#003690"/>
    <path d="M12.6373 11.4586L14.405 9.6908L9.69687 9.69669L9.69097 14.4048L11.4587 12.6371L17.3631 18.5414L15.5953 20.3092L20.3035 20.3033L20.3094 15.5951L18.5416 17.3629L12.6373 11.4586Z" fill="white"/>
    </svg>
`)

export const StampHandler = ({ activeStampMode, pageData, selectedStamp, container, rotate, scale, file, replaceFile }: StampHandlerProps) => {
  const dispatch = useAppDispatch()
  const stamps = useTypedSelector(stampsSelector)
  const qrCodes = useTypedSelector(qrCodesSelector)
  const saveTrigger = useTypedSelector(saveTriggerSelector)
  const stampMode = useTypedSelector(stampModeSelector)
  const isSelectedQRCode = useTypedSelector(isSelectedQRCodeSelector)
  const qrCodeData = useTypedSelector(qrCodeDataSelector)
  const [canvasMap, setCanvasMap] = useState<Map<string, HTMLCanvasElement>>(new Map())
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 })
  const [isStampVisible, setIsStampVisible] = useState(false)
  const [selectedStampIndex, setSelectedStampIndex] = useState<number | null>(null)
  const [isResizing, setIsResizing] = useState<boolean>(false)
  const [isDragging, setIsDragging] = useState<boolean>(false)
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const handleSize = 24

  const { allStamps } = useCustomStamp()
  const getCanvasForPage = (pageNumber: string) => canvasMap.get(pageNumber)

  const image = useMemo(() => {
    if (stampMode === 'stamp' && selectedStamp?.type) {
      return allStamps[selectedStamp.type].image

    } else if (stampMode === 'qrcode' && qrCodeData) {
      const img = new Image()
      img.src = qrCodeData
      return img
    }
  }, [stampMode, qrCodeData, selectedStamp?.type])

  // Рендер созданных штампов
  const drawStamps = useCallback(() => {
    //if ((selectedStamp?.isActive && stampMode === 'stamp') || isSelectedQRCode) return
    if (!activeStampMode) return

    canvasMap.forEach((canvas) => {
      const ctx = canvas?.getContext('2d')
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height)
      }
    })

    if (!image || !activeStampMode) return

    (stampMode === 'stamp' ? stamps : qrCodes).forEach((stamp, index) => {
      const canvas = getCanvasForPage(stamp.pageNumber)
      const ctx = canvas?.getContext('2d')
      if (!ctx || !canvas) return

      let x = stamp.left * canvas.width
      let y = stamp.top * canvas.height
      let width = stamp.width * canvas.width
      let height = stamp.height * canvas.height
      const rotationDifference = (stamp.rotate - stamp.initialRotate + 360) % 360

      ctx.save()
      if (rotationDifference !== 0) {
        ctx.translate(x + width / 2, y + height / 2)
        ctx.rotate((rotationDifference * Math.PI) / 180);

        [x, y] = rotationDifference !== 180 ? [-height / 2, -width / 2] : [-width / 2, -height / 2];
        [width, height] = rotationDifference !== 180 ? [height, width] : [width, height];
      }

      ctx.drawImage(image, x, y, width, height)

      if (selectedStampIndex !== null && selectedStampIndex === index) {
        let handlePointX = x + width - handleSize * 0.61
        let handlePointY = y + height - handleSize * 0.61
        if (rotationDifference !== 0) {

          ctx.rotate((rotationDifference * Math.PI) / 180)
          switch (rotationDifference) {
            case 90:
              handlePointX = -height / 2 - handleSize * 0.3
              handlePointY = -width / 2 - handleSize * 0.3
              break
            case 180:
              handlePointX = width / 2 - handleSize * 0.61
              handlePointY = height / 2 - handleSize * 0.61
              break
            case 270:
              handlePointX = -height / 2 - handleSize * 0.3
              handlePointY = -width / 2 - handleSize * 0.3
              break
          }
        }

        if (stampMode === 'qrcode') {
          ctx.strokeStyle = '#003690'
          ctx.lineWidth = 1
          ctx.strokeRect(x, y, width, height)
        }

        ctx.drawImage(
          handlePoint,
          handlePointX,
          handlePointY,
          handleSize,
          handleSize
        )


      }

      ctx.restore()
    })

  }, [activeStampMode, allStamps, canvasMap, selectedStampIndex, selectedStamp, isSelectedQRCode, stamps, qrCodes, image, scale])

  // Перезапись координат штампа при повороте
  const applyStampRotation = (rotate: number, stamp: Stamp, pageWidth: number, pageHeight: number) => {
    const { left, top, width, height } = stamp
    const rotationDifference = (rotate - stamp.rotate + 360) % 360

    switch (rotationDifference) {
      case 90:
        return {
          left: (pageWidth - top * pageWidth - height * pageWidth) / pageWidth,
          top: left,
          width: height,
          height: width,
        }
      case 180:
        return {
          left: (pageWidth - left * pageWidth - width * pageWidth) / pageWidth,
          top: (pageHeight - top * pageHeight - height * pageHeight) / pageHeight,
          width: width,
          height: height,
        }
      case 270:
        return {
          left: top,
          top: (pageHeight - left * pageHeight - width * pageHeight) / pageHeight,
          width: height,
          height: width,
        }
      default:
        return {
          left,
          top,
          width,
          height,
        }
    }
  }

  useEffect(() => {
    const applyRotate = (array: Stamp[]) => {
      return array.map((stamp) => {
        const canvas = getCanvasForPage(stamp.pageNumber)
        if (!canvas) return stamp
        const { left, top, width, height } = applyStampRotation(rotate, stamp, canvas.width, canvas.height)
        return {
          ...stamp,
          height,
          left,
          top,
          width,
          rotate
        }
      })
    }

    if (stampMode === 'stamp' && stamps.length !== 0) {
      dispatch(setStamps(applyRotate(stamps)))
    }
    if (stampMode === 'qrcode' && qrCodes.length !== 0) {
      dispatch(setQRCodes(applyRotate(qrCodes)))
    }
  }, [rotate])

  // Обработчик клика по сущесвующим штампам
  const handleMouseDown = useCallback((e: MouseEvent) => {
    if (e.buttons !== 1) return
    const components = stampMode === 'stamp' ? stamps : qrCodes

    const selected = components.findIndex((stamp) => {
      const canvas = getCanvasForPage(stamp.pageNumber)

      if (!canvas) return false
      if (!(e.target instanceof HTMLCanvasElement) || e.target !== canvas) return false
      const rect = canvas.getBoundingClientRect()
      const clientX = e.clientX - rect.left
      const clientY = e.clientY - rect.top
      return (
        clientX >= stamp.left * canvas.width &&
        clientX <= (stamp.left + stamp.width) * canvas.width &&
        clientY >= stamp.top * canvas.height &&
        clientY <= (stamp.top + stamp.height) * canvas.height
      )
    })

    if (selected !== -1) {
      const canvas = getCanvasForPage(components[selected].pageNumber)
      if (!canvas) return
      const rect = canvas.getBoundingClientRect()
      const { width, height, left, top } = components[selected]
      const clientX = e.clientX - rect.left
      const clientY = e.clientY - rect.top

      const isBottomRightHandle =
        clientX >= left * canvas.width + width * canvas.width - (handleSize / 2) &&
        clientX <= left * canvas.width + width * canvas.width + (handleSize / 2) &&
        clientY >= top * canvas.height + height * canvas.height - (handleSize / 2) &&
        clientY <= top * canvas.height + height * canvas.height + (handleSize / 2)

      if (isBottomRightHandle) {
        setIsResizing(true)
      } else {
        canvas.style.cursor = 'move'
        setIsDragging(true)
        setStartPosition({
          x: clientX - left * canvas.width,
          y: clientY - top * canvas.height
        })
      }
      setSelectedStampIndex(selected)
    } else {
      setSelectedStampIndex(null)
    }
  }, [canvasMap/* canvasMap, getCanvasForPage, stamps, qrCodes */])

  // Обработчик перемещения курсора с выделенным штампом
  const handleMouseMove = useCallback((e: MouseEvent) => {
    if (!canvasMap || isSelectedQRCode || selectedStamp?.isActive) return

    const components = stampMode === 'stamp' ? stamps : qrCodes

    // Изменение стилей курсора
    const updateCursorStyle = () => {
      canvasMap.forEach((canvas) => {
        const rect = canvas.getBoundingClientRect()
        const clientX = e.clientX - rect.left
        const clientY = e.clientY - rect.top

        let cursorStyle = "inherit"

        for (let index = 0; index < components.length; index++) {
          const stamp = components[index]
          const canvasForStamp = getCanvasForPage(stamp.pageNumber)

          if (canvasForStamp !== canvas) continue

          const x = stamp.left * canvas.width
          const y = stamp.top * canvas.height
          const stampWidth = stamp.width * canvas.width
          const stampHeight = stamp.height * canvas.height

          const isInsideStamp = clientX >= x &&
            clientX <= x + stampWidth &&
            clientY >= y &&
            clientY <= y + stampHeight

          if (isInsideStamp) {
            cursorStyle = "pointer"

            if (selectedStampIndex === index) {
              const isBottomRightHandle =
                clientX >= x + stampWidth - handleSize / 2 &&
                clientX <= x + stampWidth + handleSize / 2 &&
                clientY >= y + stampHeight - handleSize / 2 &&
                clientY <= y + stampHeight + handleSize / 2

              if (isDragging || isInsideStamp) {
                cursorStyle = "move"
              }
              if (isResizing || isBottomRightHandle) {
                cursorStyle = "nwse-resize"
              }
            }
            break
          }
        }
        canvas.style.cursor = cursorStyle
      })
    }

    updateCursorStyle()
    // Логика растягивания штампа
    if (isResizing && selectedStampIndex !== null) {
      const canvas = getCanvasForPage(components[selectedStampIndex].pageNumber)
      if (!canvas) return

      const rect = canvas.getBoundingClientRect()
      const clientX = e.clientX - rect.left
      const clientY = e.clientY - rect.top

      const updatedStamps = components.map((stamp, index) => {
        if (index !== selectedStampIndex) return stamp

        const rotationDifference = (stamp.rotate - stamp.initialRotate + 360) % 360
        let newWidth, newHeight
        const aspectRatio = stampMode === 'stamp' ? DEFAULT_STAMP_ASPECTRATIO : DEFAULT_QRCODE_ASPECTRATIO

        switch (rotationDifference) {
          case 0:
          case 180:
          default:
            newWidth = Math.max(canvas.width * 0.01, clientX - stamp.left * canvas.width)
            newHeight = Math.max(canvas.width * 0.01 * aspectRatio, newWidth * aspectRatio)
            break
          case 90:
          case 270:
            newHeight = Math.max(canvas.height * 0.01, clientY - stamp.top * canvas.height)
            newWidth = Math.max(canvas.height * 0.01 * aspectRatio, newHeight * aspectRatio)
            break
        }

        return {
          ...stamp,
          width: newWidth / canvas.width,
          height: newHeight / canvas.height,
        }
      })
      stampMode === 'stamp'
        ? dispatch(setStamps(updatedStamps))
        : dispatch(setQRCodes(updatedStamps))
    }
    // Логика перетаскивания штампа
    if (isDragging && selectedStampIndex !== null) {
      const canvas = getCanvasForPage(components[selectedStampIndex].pageNumber)
      if (!canvas) return

      const rect = canvas.getBoundingClientRect()
      const clientX = e.clientX - rect.left
      const clientY = e.clientY - rect.top

      const updatedStamps = components.map((stamp, index) => index === selectedStampIndex
        ? {
          ...stamp,
          left: (clientX - startPosition.x) / canvas.width,
          top: (clientY - startPosition.y) / canvas.height
        }
        : stamp
      )
      stampMode === 'stamp'
        ? dispatch(setStamps(updatedStamps))
        : dispatch(setQRCodes(updatedStamps))
    }

  }, [canvasMap, selectedStampIndex, isDragging, isResizing])

  // Обработчик при отжатии кнопки мыши
  const handleMouseUp = useCallback(() => {
    if (isDragging || isResizing) {
      setIsDragging(false)
      setIsResizing(false)
    }
  }, [isDragging, isResizing])

  // Превью штампа в границах страниц до его постановки на документ
  useEffect(() => {
    const containerRef = container.current
    if (
      !containerRef
      || ((!selectedStamp?.isActive && stampMode === 'stamp')
        || (!isSelectedQRCode && stampMode === 'qrcode'))
    ) return

    const handleMouseMove = (e: MouseEvent) => {
      const containerRect = containerRef.getBoundingClientRect()
      const isVisible = pageData.some((ref) => {
        const page = ref?.current
        if (!page) return false

        const rect = page.getBoundingClientRect()
        return (
          e.clientX >= rect.left && e.clientX >= containerRect.left &&
          e.clientX <= rect.right && e.clientX <= containerRect.right &&
          e.clientY >= rect.top && e.clientY >= containerRect.top &&
          e.clientY <= rect.bottom && e.clientY <= containerRect.bottom
        )
      })

      setCursorPosition({ x: e.clientX, y: e.clientY })
      setIsStampVisible(isVisible)
    }

    const handleClick = (e: MouseEvent) => {
      for (const ref of pageData) {
        const page = ref?.current
        const canvas = page?.parentElement?.querySelector('#overlay-canvas canvas') as HTMLCanvasElement | null
        if (!page || !canvas) continue
        const rect = canvas.getBoundingClientRect()
        if (
          e.clientX >= rect.left &&
          e.clientX <= rect.right &&
          e.clientY >= rect.top &&
          e.clientY <= rect.bottom
        ) {

          const defaultHeight = stampMode === 'stamp' ? DEFAULT_STAMP_HEIGHT * scale : DEFAULT_QRCODE_HEIGHT * scale
          const defaultWidth = stampMode === 'stamp' ? DEFAULT_STAMP_WIDTH * scale : DEFAULT_QRCODE_WIDTH * scale

          const left = (e.clientX - rect.left) / canvas.width - (defaultWidth / 2) / canvas.width
          const top = (e.clientY - rect.top) / canvas.height - (defaultHeight / 2) / canvas.height
          const height = defaultHeight / canvas.height
          const width = defaultWidth / canvas.width
          const activePageNumber = page.getAttribute('data-page-number')

          if (activePageNumber) {
            if (stampMode === 'stamp') {
              dispatch(setStamps([...stamps, {
                pageNumber: activePageNumber,
                top,
                left,
                height,
                width,
                rotate,
                initialRotate: rotate,
              }]))
              dispatch(setSelectedStamp({ type: selectedStamp!.type, isActive: false }))
            } else {
              dispatch(setQRCodes([...qrCodes, {
                pageNumber: activePageNumber,
                top,
                left,
                height,
                width,
                rotate,
                initialRotate: rotate
              }]))
              dispatch(setIsSelectedQRCode(false))
            }
          }
          canvas.style.cursor = 'pointer'
          setCursorPosition({ x: 0, y: 0 })
          break
        }
      }
    }

    document.addEventListener('mousemove', handleMouseMove)
    containerRef.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      containerRef.removeEventListener('click', handleClick)
    }
  }, [pageData, container, selectedStamp, isSelectedQRCode, stampMode, scale])

  // Назначение событий мыши для установленных штампов
  useEffect(() => {

    const containerRef = container.current
    if (!activeStampMode || !containerRef) return
    if (containerRef && (stamps.length > 0 || qrCodes.length > 0)) {
      containerRef.addEventListener('mousedown', handleMouseDown)
      containerRef.addEventListener('mousemove', handleMouseMove)
      containerRef.addEventListener('mouseup', handleMouseUp)
    }
    return () => {
      containerRef.removeEventListener('mousedown', handleMouseDown)
      containerRef.removeEventListener('mousemove', handleMouseMove)
      containerRef.removeEventListener('mouseup', handleMouseUp)

    }
  }, [container, handleMouseDown, handleMouseMove, stamps])

  // Запуск рендера штампов
  useEffect(() => {
    drawStamps()
  }, [stamps, qrCodes, drawStamps, selectedStampIndex, scale])

  // Обновление коллекции canvas
  useEffect(() => {
    const newCanvasMap: Map<string, HTMLCanvasElement> = new Map();

    (stampMode === 'stamp' ? stamps : qrCodes).forEach((stamp) => {
      const page = pageData.find((ref) => ref.current?.getAttribute('data-page-number') === stamp.pageNumber)?.current
      const overlayCanvas = page?.parentElement?.querySelector('#overlay-canvas canvas') as HTMLCanvasElement | null

      if (overlayCanvas) {
        newCanvasMap.set(stamp.pageNumber, overlayCanvas)
      }
    })

    setCanvasMap(newCanvasMap)
  }, [stamps, qrCodes, pageData, rotate])

  //Сохранение документа
  useEffect(() => {
    if (!saveTrigger) return
    setSelectedStampIndex(null)
    setIsLoading(true)
    const createData = async () => {
      try {
        const loadingTask = pdfjs.getDocument(file)
        const pdfDocument = await loadingTask.promise
        const pdfData = await pdfDocument.getData()

        const pdfDoc = await PDFDocument.load(pdfData)

        const svgData = stampMode === 'stamp' ? allStamps[selectedStamp!.type].data : qrCodeData!
        const jpgData = await svgToJpg(svgData)
        const stampImage = await pdfDoc.embedPng(jpgData);/* embedJpg */
        (stampMode === 'stamp' ? stamps : qrCodes).forEach((stamp) => {

          const stampDegress = stamp.initialRotate
          const page = pdfDoc.getPage(Number(stamp.pageNumber) - 1)
          const pageWidth = page.getWidth()
          const pageHeight = page.getHeight()
          const { left: stampLeft, top: stampTop, width: stampWidth, height: stampHeight } = applyStampRotation(stampDegress, stamp, pageWidth, pageHeight)
          let x = stampLeft * pageWidth
          let y = pageHeight - stampHeight * pageHeight - stampTop * pageHeight
          let width = stampWidth * pageWidth
          let height = stampHeight * pageHeight

          if (stampDegress !== 0) {
            switch (stampDegress) {
              case 90:
                x = pageWidth - (pageWidth - stampTop * pageWidth - stampHeight * pageWidth)
                y = stampLeft * pageHeight
                width = stampWidth * pageHeight
                height = stampHeight * pageWidth
                break
              case 180:
                x = pageWidth - stampLeft * pageWidth
                y = stampTop * pageHeight + stampHeight * pageHeight
                width = stampWidth * pageWidth
                height = stampHeight * pageHeight
                break
              case 270:
                x = pageWidth - stampTop * pageWidth - stampHeight * pageWidth
                y = pageHeight - stampLeft * pageHeight
                width = stampWidth * pageHeight
                height = stampHeight * pageWidth
                break
            }
          }

          page.drawImage(stampImage, {
            x,
            y,
            width,
            height,
            rotate: degrees(stampDegress)
          })
        })

        const pdfBytes = await pdfDoc.save()
        return pdfBytes
      } catch (error) {
        console.error('Ошибка:', error)
        setIsLoading(false)
        throw error
      }
    }

    createData()
      .then((pdfBytes) => {
        const fileName = file.split('/').pop()?.split('#')[0]?.split('?')[0] || "document.pdf"
        const blob = new Blob([pdfBytes], { type: "application/pdf" })
        const pdfFile = new File([blob], fileName, { type: "application/pdf" })
        replaceFile(pdfFile)
        /* const url = URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = url
        a.download = 'TEST'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(url) */
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      })

    dispatch(setSaveTrigger(false))

  }, [saveTrigger])

  // Удаление выбранного штампа
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const isMacOS = navigator.userAgent.includes('Mac')

      if (e.key === 'Escape') {
        if (selectedStamp?.isActive || isSelectedQRCode) {
          stampMode === 'stamp'
            ? dispatch(setSelectedStamp({ type: selectedStamp!.type, isActive: false }))
            : dispatch(setIsSelectedQRCode(false))
          setCursorPosition({ x: 0, y: 0 })
        }
        return
      }

      const components = stampMode === 'stamp' ? stamps : qrCodes
      if (selectedStampIndex === null) return

      if (isMacOS) {
        if (e.key === 'Backspace' && !e.getModifierState('Fn')) {
          e.preventDefault()
          removeStamp(components)
        }
      } else {
        if (e.key === 'Delete') {
          removeStamp(components)
        }
      }
    }

    const removeStamp = (components: typeof stamps | typeof qrCodes) => {
      if (selectedStampIndex === null) return
      const canvas = getCanvasForPage(components[selectedStampIndex].pageNumber)
      const updatedComponents = components.filter((_, index) => index !== selectedStampIndex)
      stampMode === 'stamp'
        ? dispatch(setStamps(updatedComponents))
        : dispatch(setQRCodes(updatedComponents))
      setSelectedStampIndex(null)

      if (canvas) {
        canvas.style.cursor = 'default'
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [stamps, qrCodes, selectedStampIndex, getCanvasForPage])

  // Стиль курсора во время установки штампа
  useEffect(() => {
    const containerRef = container.current
    if (!containerRef) return
    selectedStamp?.isActive || isSelectedQRCode ? containerRef.style.cursor = 'crosshair' : containerRef.style.cursor = 'inherit'
  }, [container, selectedStamp, isSelectedQRCode])

  // Очистка страниц после размонтирования компонента
  useEffect(() => {
    return () => {
      dispatch(setSelectedStamp(null))
      dispatch(setStamps([]))
      dispatch(setSaveTrigger(false))
      dispatch(setIsSelectedQRCode(false))
      dispatch(setQRCodes([]))
      dispatch(setStampMode('stamp'))
    }
  }, [])

  if (isStampVisible && selectedStamp?.isActive) {
    return (
      <StampPreview top={cursorPosition.y} left={cursorPosition.x} width={DEFAULT_STAMP_WIDTH * scale} height={DEFAULT_STAMP_HEIGHT * scale}>
        <img src={allStamps[selectedStamp.type].data} alt={allStamps[selectedStamp.type].name} width='100%' />
      </StampPreview>
    )
  }
  if (stampMode === 'qrcode' && isSelectedQRCode && qrCodeData && isStampVisible) {
    return (
      <StampPreview top={cursorPosition.y} left={cursorPosition.x} width={DEFAULT_QRCODE_WIDTH * scale} height={DEFAULT_QRCODE_HEIGHT * scale}>
        <img src={qrCodeData} alt={'qr-код'} width='100%' />
      </StampPreview>
    )
  }
  if (isLoading) {
    return (
      <Backdrop open={true} sx={{ zIndex: 999, marginLeft: '350px' }}>
        <Stack spacing={3} alignItems='center'>
          <LoadingCube />
          <LoadingText >Документ обрабатывается...</LoadingText>
        </Stack>

      </Backdrop>
    )
  } else return null
}
