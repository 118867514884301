import styled, { keyframes } from "styled-components";
interface StampPreviewStyle {
  top: number
  left: number
  width: number
  height: number
}

const loadingCube = keyframes`
  0%   {background-size:35px 15px,15px 15px,15px 35px,35px 35px}
  25%  {background-size:35px 35px,15px 35px,15px 15px,35px 15px}
  50%  {background-size:15px 35px,35px 35px,35px 15px,15px 15px}
  75%  {background-size:15px 15px,35px 15px,35px 35px,15px 35px}
  100% {background-size:35px 15px,15px 15px,15px 35px,35px 35px}
`

export const StampPreview = styled.div<StampPreviewStyle>`
  position: absolute;
  margin: 0 !important;
  top: ${props => props.top + 'px'};
  left: ${props => props.left + 'px'};
  width: ${props => props.width + 'px'};
  height: ${props => props.height + 'px'};
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 5;
`
export const LoadingText = styled.p`
  font-weight: bold;
  display: inline-block;
  color: #fff;
  font-size: 30px;
`
export const LoadingCube = styled.div`
  width: 55px;
  aspect-ratio: 1;
  --g1:conic-gradient(from  90deg at top    3px left  3px,#0000 90deg,#fff 0);
  --g2:conic-gradient(from -90deg at bottom 3px right 3px,#0000 90deg,#fff 0);
  background:
    var(--g1),var(--g1),var(--g1),var(--g1), 
    var(--g2),var(--g2),var(--g2),var(--g2);
  background-position: 0 0,100% 0,100% 100%,0 100%;
  background-size: 25px 25px;
  background-repeat: no-repeat;
  animation: ${loadingCube} 1.5s infinite;
`
