import { useGetQRCodeDocInfoQuery } from "@api/qrCode"
import useQuery from "@hooks/useQuery"
import { Box, Stack, Typography, Button } from "@mui/material"
import { useParams } from "react-router-dom"
import { ColorStatus, BlockWrapper, TextData, QRWrapper, TomStatusText, TomSection, StatusSection, DesignLogo, LogoWrapper, WithChangeLabel } from "./QRCodeDoc.styles"
import { DocData, DocStatus } from "."
import AddLinkIcon from '@mui/icons-material/AddLink';
import Progress from "@components/Progress"
import { useSnackbar } from "notistack"
import { ProjectPhaseEn, projectPhaseEnShortToRuShort, projectPhaseRuShortToEnShort } from "@typesGlobal/project"

export const QRCodeDoc = () => {
  const { docId: docIdString } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const docId = Number(docIdString)
  const queryHandler = useQuery()
  const type: ProjectPhaseEn | null = queryHandler.get('phase')
  const { data } = useGetQRCodeDocInfoQuery({ docId, type: projectPhaseEnShortToRuShort[type!] }, { skip: !type, refetchOnMountOrArgChange: true })
  const { changeList, doc, projectId, remarksCount, tom, type: tomType } = data || {}
  const isVersion = !!doc?.version
  const isChange = !!doc?.change
  const lastChangeNumber = changeList && changeList?.length > 0 ? changeList[changeList.length - 1].number : null
  const docStatus: DocStatus =
    tom?.annulledDate || (doc?.version && doc.version.isActual === false) ? 'notActual'
      : changeList && changeList?.length > 0 && (isVersion ? true : lastChangeNumber !== doc?.change.number) ? 'actualWithChanges'
        : 'actual'

  const docData: DocData | undefined = isVersion
    ? {
      doc: 'Версия',
      isActual: doc?.version.isActual ? 'Актуальна' : 'Неактуальна',
      number: doc.version.number,
      createdAt: doc.version.createdAt,
      prodDate: doc.version.prodDate,
      prodTitle: 'Выдан в производство'
    } : isChange
      ? {
        doc: 'Изменение',
        number: doc.change.number,
        createdAt: doc.change.createdAt,
        prodDate: doc.change.prodDate,
        prodTitle: 'Выдано в производство'
      } : undefined

  const handleShareLink = async () => {
    if (!tom || !tomType || !projectId) return
    const url = new URL(window.location.href)
    const link = `${url.origin}/project/${projectId}/tom/${tom.id}?phase=${projectPhaseRuShortToEnShort[tomType]}`

    const shareData = {
      title: tom.title,
      text: tom.description,
      url: link,
    }

    const windowsOrMacUser = navigator.userAgent.includes('Win')
      || navigator.userAgent.includes('Mac')
      ? true
      : false

    try {
      if (navigator.share && !windowsOrMacUser) {
        await navigator.share(shareData)
      } else {
        navigator.clipboard.writeText(link).then(() => {
          enqueueSnackbar(
            "Ссылка скопирована",
            { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' }, style: { width: 220, margin: 'auto' } }
          )
        })
      }
    } catch (error) {
      console.error('Не удалось поделиться ссылкой', error)
    }
  }

  if (!data) return <Progress />
  return (
    <Stack p={2} pr={0} width='100%' alignItems='center' overflow='hidden'>
      <StatusSection>
        <LogoWrapper>
          <DesignLogo />
        </LogoWrapper>
        <TomStatusText>{tom?.annulledDate ? 'Аннулирован' : 'Актуален'}</TomStatusText>
        {docStatus === 'actualWithChanges' &&
          <WithChangeLabel>
            <Typography>Добавлены изменения</Typography>
          </WithChangeLabel>
        }
      </StatusSection>
      <TomSection spacing={0.75}>
        <Stack spacing={0.75}>
          <Typography variant="body2" ml={1.25}>Информация о томе</Typography>
          <BlockWrapper spacing={2.5}>
            {(tom?.annulledDate || docData?.prodDate) &&
              <Stack spacing={1}>
                <Stack direction='row' justifyContent='space-between'>
                  <ColorStatus status={docData?.prodDate ? 'В ПРОИЗВОДСТВЕ' : 'Аннулирован'}>
                    {docData ? docData.prodTitle : 'Аннулирован'}
                  </ColorStatus>
                  <Typography>{docData ? docData.prodDate : tom?.annulledDate}</Typography>
                </Stack>
              </Stack>
            }

            {docData &&
              <Stack spacing={1}>
                <Stack direction='row' justifyContent='space-between'>
                  <Typography variant="body2">{docData.doc}</Typography>
                  {isVersion && docData.isActual &&
                    <ColorStatus status={docData.isActual}>
                      {docData.isActual}
                    </ColorStatus>
                  }
                </Stack>
                <Box display='inline-flex'>
                  <Typography mr={1}>{docData.number}</Typography>
                  <TextData>(Загружено {docData.createdAt})</TextData>
                </Box>
              </Stack>
            }

            <Stack spacing={1}>
              <Typography variant="body2">Шифр:</Typography>
              <Typography>{tom!.title}</Typography>
            </Stack>

            <Stack spacing={1}>
              <Typography variant="body2">Наименование:</Typography>
              <Typography>{tom!.description}</Typography>
            </Stack>

            {remarksCount !== null &&
              <Stack spacing={1}>
                <Typography variant="body2">Замечания:</Typography>
                <Typography>{remarksCount === 0 ? 'нет' : remarksCount}</Typography>
              </Stack>
            }
          </BlockWrapper>
        </Stack>
        {changeList && changeList?.length > 0 &&
          <Stack spacing={0.75}>
            <Typography variant="body2" ml={1.25}>История изменений</Typography>
            <BlockWrapper spacing={2.5}>
              {changeList.map((doc, index) =>
                <Stack key={index} direction='row' justifyContent='space-between'>
                  <Stack direction='row' spacing={1.5}>
                    <Typography>{doc.number}</Typography>
                    <ColorStatus status={doc.status}>{doc.status}</ColorStatus>
                  </Stack>
                  <Typography variant='body2' fontSize={16}>{doc.createdAt}</Typography>
                </Stack>
              )}
            </BlockWrapper>
          </Stack>
        }
        <Box position='sticky' bottom={0} pb={1.5}>
          <BlockWrapper>
            <Button startIcon={<AddLinkIcon />} fullWidth onClick={handleShareLink}>
              Поделиться ссылкой на том
            </Button>
          </BlockWrapper>
        </Box>
      </TomSection>
      <QRWrapper actual={docStatus}></QRWrapper>
    </Stack>
  )
}