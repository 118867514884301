import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PdfVieweState, QRCode, SelectedStamp, Stamp, StampMode } from "./pdfViewer.types";

let initialState: PdfVieweState = {
  selectedStamp: null,
  stamps: [],
  saveTrigger: false,
  stampMode: 'stamp',
  isSelectedQRCode: false,
  qrCodeData: null,
  qrCodes: []
}

const pdfViewersSlice = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
    setSelectedStamp: (state, { payload }: PayloadAction<SelectedStamp | null>) => {
      state.selectedStamp = payload
    },
    setStamps: (state, { payload }: PayloadAction<Stamp[]>) => {
      state.stamps = payload
    },
    setSaveTrigger: (state, { payload }: PayloadAction<boolean>) => {
      state.saveTrigger = payload
    },
    setStampMode: (state, { payload }: PayloadAction<StampMode>) => {
      state.stampMode = payload
    },
    setIsSelectedQRCode: (state, { payload }: PayloadAction<boolean>) => {
      state.isSelectedQRCode = payload
    },
    setQrCodeData: (state, { payload }: PayloadAction<string | null>) => {
      state.qrCodeData = payload
    },
    setQRCodes: (state, { payload }: PayloadAction<QRCode[]>) => {
      state.qrCodes = payload
    },
  },
})

export const { reducer: pdfViewerReducer } = pdfViewersSlice
export const {
  setSelectedStamp,
  setStamps,
  setSaveTrigger,
  setStampMode,
  setIsSelectedQRCode,
  setQrCodeData,
  setQRCodes,
} = pdfViewersSlice.actions
