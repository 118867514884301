import { AdminSettings } from 'pages/Administration/AdminSettings'
import { ModelView } from 'pages/ModelView/ModelView'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import AdminLayout from '@layouts/AdminLayout'
import { AgreementLayout } from '@layouts/AgreementLayout/AgreementLayout'
import { DocumentsLayout } from '@layouts/DocumentsLayout'
import HomeLayout from '@layouts/HomeLayout'
import { Layout } from '@layouts/Layout'
import { ModelsLayout } from '@layouts/ModelsLayout/ModelsLayout'
import { WorksLayout } from '@layouts/WorksLayout/WorksLayout'

import AdminPd from '@pages/Administration/AdminPd'
import AdminProjects from '@pages/Administration/AdminProjects'
import AdminRd from '@pages/Administration/AdminRd'
import AdminUsers from '@pages/Administration/AdminUsers'
import AddUser from '@pages/Administration/AdminUsers/AddUser'
import EditUser from '@pages/Administration/AdminUsers/EditUser'
import { ProjectManagement } from '@pages/Administration/ProjectManagement'
import DocView from '@pages/DocView'
import { Toms } from '@pages/Docs'
import Home from '@pages/Home'
import { Models } from '@pages/Models/Models'
import Objects from '@pages/Objects'
import { PocketView } from '@pages/PocketView'
import { Pockets } from '@pages/Pockets'
import { ProjectInfo } from '@pages/ProjectInfo'
import { RemarksPage } from '@pages/RemarksPage'
import Schedules from '@pages/Schedules/Schedules'
import { Works } from '@pages/Schedules/components/Works'
import { InternalAgreementSchemes } from '@pages/Schemes/InternalAgreementSchemes'
import { ProjectAgreement } from '@pages/Schemes/ProjectAgreement'
import { ProjectAgreementForm } from '@pages/Schemes/ProjectAgreementForm'
import { AgreementOverview } from '@pages/AgreementOverview'
import { QRCodeDoc } from '@pages/QRCodeDoc'

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path='' element={<Layout />}>
        <Route path='' element={<HomeLayout />}>
          <Route path='home' element={<Home />} />
          <Route path='project/:projectId' element={<ProjectInfo />} />

          <Route path='*' element={<Navigate to='/home' />} />
        </Route>

        <Route path='administration' element={<AdminLayout />}>
          <Route path='users' element={<AdminUsers />} />
          <Route path='users/add' element={<AddUser />} />
          <Route path='users/edit/:userId' element={<EditUser />} />

          <Route path='projects' element={<AdminProjects />} />
          <Route path='projects/add' element={<ProjectManagement />} />
          <Route path='projects/edit/:projectId' element={<ProjectManagement />} />

          <Route path='project/:projectId/agreement' element={<ProjectAgreement />} />
          <Route path='project/:projectId/agreement/add' element={<ProjectAgreementForm />} />
          <Route path='project/:projectId/agreement/edit/:stageId' element={<ProjectAgreementForm />} />

          <Route path='rd' element={<AdminRd />} />
          <Route path='pd' element={<AdminPd />} />

          <Route path='settings' element={<AdminSettings />} />

          <Route path='*' element={<Navigate to='/administration/users' />} />
        </Route>

        <Route path='project/:projectId/schedules' element={<WorksLayout />}>
          <Route path='' element={<Schedules />} />
          <Route path=':scheduleId' element={<Works />} />
        </Route>

        <Route path='project/:projectId/schemes' element={<AgreementLayout />}>
          <Route path='' element={<InternalAgreementSchemes />} />
          <Route path='tom/:tomId' element={<AgreementOverview />} />
          <Route path='innermap' element={<InternalAgreementSchemes />} />
          <Route path='roadmap' element={<ProjectAgreement />} />
          <Route path='roadmap/add' element={<ProjectAgreementForm />} />
          <Route path='roadmap/edit/:stageId' element={<ProjectAgreementForm />} />
        </Route>

        {/*  <Route path='project/:projectId/coordination' element={<Сoordination />} /> */}

        <Route path='project/:projectId/pockets' element={<Pockets />} />
        <Route path='project/:projectId/pockets/:pocketId' element={<PocketView />} />

        <Route path='project/:projectId/remarks' element={<RemarksPage />} />

        <Route path='project/:projectId/tim/models' element={<ModelsLayout />}>
          <Route path='' element={<Models />} />
          <Route path='view' element={<ModelView />} />
        </Route>

        <Route path='project/:projectId' element={<DocumentsLayout />}>
          {/* Refers to TitleCell work redirect */}
          <Route path='tim' element={<Objects />} />
          <Route path='objects' element={<Objects />} />
          <Route path='toms' element={<Toms />} />
          <Route path='tom/:tomId' element={<DocView />} />
          <Route path='tom/cmn' element={<DocView />} />
        </Route>

        <Route index element={<Navigate to='/home' />} />
      </Route>
      <Route path='qr/:docId' element={<QRCodeDoc />} />
    </Routes>
  )
}
