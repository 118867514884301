import { Dispatch, RefObject, SetStateAction } from "react"
import { Annotation, AnnotationType } from "../../PragmaPdfViewer.types"

export const DEFAULT_FONT_SIZE = 16
export const DEFAULT_TEXTBOX_PADDING = 5

export interface CanvasAnnotationsProps {
  width?: number
  height?: number
  scale: number
  scaleFactor: number
  rotate: number
  setAnnotations: (annotations: Annotation[] | Annotation) => void
  annotations: Annotation[]
  annotationType: AnnotationType | null
  canEditAnnotation: boolean
  documentRef: RefObject<HTMLDivElement>
  pageNumber: number
  isCreateMode : boolean
  isSelectMode: boolean
  selectedAnnotationId: number | null
  selectAnnotationId: (id: number) => void
  stampMode: boolean
}

export interface UseDefaultToolProps {
  canvasRef: RefObject<HTMLCanvasElement | null>
  rotate: number
  scale: number
  scaleFactor: number
  setAnnotations: (annotations: Annotation[] | Annotation) => void
  annotations: Annotation[]
  selectedRect: { index: number, type: AnnotationType } | null
  isCreateMode: boolean
  selectedAnnotationId: number | null
  setSelectedRect: (selectedRect: { index: number, type: AnnotationType } | null) => void
  drawAnnotations: () => void
  setDataTextBox: Dispatch<SetStateAction<DataTextBox | null>>
  selectTextAnnotation: (index: number) => void
}

export interface UseUseHighLightToolProps {
  documentRef: RefObject<HTMLDivElement>
  canvasRef: RefObject<HTMLCanvasElement | null>
  annotations: Annotation[]
  pageNumber: number
  rotate: number
  selectedRect: { index: number, type: AnnotationType } | null
  isCreateMode : boolean
  selectedAnnotationId: number | null
  annotationType: AnnotationType | null
  setSelectedRect: (selectedRect: { index: number, type: AnnotationType } | null) => void
  setAnnotations: (annotations: Annotation[] | Annotation) => void
  drawAnnotations: () => void
}

export interface UseSelectAnnotationsProps {
  canvasRef: RefObject<HTMLCanvasElement | null>
  annotations: Annotation[]
  scale: number
  selectAnnotationId: (index: number) => void
}

export interface DataTextBox {
  index: number
  text: string
  left: number
  top: number
  width: number
  height: number
}
