import { TomIi, TomIiChange, TomIiDocument, TomIiFull, TomIiMultiUploadResponse } from '../../api/iiPhase'
import { TomIrd, TomIrdChange, TomIrdDocument, TomIrdFull, TomIrdMultiUploadResponse } from '../../api/irdPhase'
import { TomPd, TomPdChange, TomPdDocument, TomPdFull, TomPdMultiUploadResponse } from '../../api/pdPhase'
import { TomRd, TomRdChange, TomRdDocument, TomRdFull, TomRdMultiUploadResponse } from '../../api/rdPhase'
import {
  SelectedIiTomData,
  SelectedIrdTomData,
  SelectedPdTomData,
  SelectedRdTomData,
} from '../../pages/Docs/components/ExportArchiveDrawer/components/ExportArchiveDrawerForm/ExportArchiveDrawerForm.types'
import { LegendColors } from '../global'
import { ProjectPhase } from '../project'

export type TomStatusCountEn =
  | 'agreed'
  | 'all'
  | 'correction'
  | 'development'
  | 'inProd'
  | 'noNeedAgree'
  | 'notInWork'
  | 'toAgreed'
  | 'annulled'

export interface TomStatusCountEnValue extends Record<TomStatusCountEn, number> {
  agreed: number
  all: number
  correction: number
  development: number
  inProd: number
  noNeedAgree: number
  notInWork: number
  toAgreed: number
}
export type TomStatus =
  | 'Не согласуется'
  | 'Согласовано'
  | 'Корректировка'
  | 'На согласовании'
  | 'Разработка'
  | 'Не в работе'

  export type TomStatusUpperCase =
  | 'НЕ СОГЛАСУЕТСЯ'
  | 'СОГЛАСОВАНО'
  | 'КОРРЕКТИРОВКА'
  | 'НА СОГЛАСОВАНИИ'
  | 'РАЗРАБОТКА'
  | 'НЕ В РАБОТЕ'

export type TomStatusTabLabels =
  | 'Все документы'
  | 'В производстве'
  | 'Не согласуется'
  | 'Согласовано'
  | 'Корректировка'
  | 'На согласовании'
  | 'Разработка'
  | 'Не в работе'
  | 'Аннулирован'

export const tomLabelByTabCountEn: Record<TomStatusCountEn, TomStatusTabLabels> = {
  all: 'Все документы',
  inProd: 'В производстве',
  noNeedAgree: 'Не согласуется',
  agreed: 'Согласовано',
  correction: 'Корректировка',
  toAgreed: 'На согласовании',
  development: 'Разработка',
  notInWork: 'Не в работе',
  annulled: 'Аннулирован',
}

export const tomTabNamesArray: TomStatusTabLabels[] = [
  'Все документы',
  'В производстве',
  'Не согласуется',
  'Согласовано',
  'Корректировка',
  'На согласовании',
  'Разработка',
  'Не в работе',
]
export const tomStatusesArray: TomStatus[] = [
  'Не согласуется',
  'Согласовано',
  'Корректировка',
  'На согласовании',
  'Разработка',
  'Не в работе',
]
export const tomStatusByTabLabel: Record<
  Exclude<TomStatusTabLabels, 'Все документы' | 'В производстве' | 'Аннулирован'>,
  TomStatus
> = {
  'Не согласуется': 'Не согласуется',
  Согласовано: 'Согласовано',
  Корректировка: 'Корректировка',
  'На согласовании': 'На согласовании',
  Разработка: 'Разработка',
  'Не в работе': 'Не в работе',
}
export const IN_PROD_TOM_TITLE = 'В производстве'
export const TOM_ANNULLED_TITLE = 'Аннулирован'

export const tomStatusesColors: Record<TomStatus | 'В производстве', LegendColors> = {
  'В производстве': 'darkLime',
  'Не согласуется': 'darkPink',
  Согласовано: 'lightgreen',
  Корректировка: 'red',
  'На согласовании': 'yellow',
  Разработка: 'blue',
  'Не в работе': 'disabled',
}

export type TomTabStatus = TomStatus | 'В производстве' | 'Аннулирован'

// TODO refactor in entire project
export type Tom = TomRd | TomPd | TomIi | TomIrd
export type TomFull = TomRdFull | TomPdFull | TomIiFull | TomIrdFull
export type TomDocument = TomRdDocument | TomPdDocument | TomIiDocument | TomIrdDocument
export type TomChange = TomRdChange | TomPdChange | TomIiChange | TomIrdChange

export type TomAccessStatus = 'open' | 'close'

export type TomMultiUploadResponse =
  | TomRdMultiUploadResponse
  | TomPdMultiUploadResponse
  | TomIiMultiUploadResponse
  | TomIrdMultiUploadResponse

export type SelectedTomData = SelectedRdTomData | SelectedPdTomData | SelectedIiTomData | SelectedIrdTomData

export type TomType = 'ИРД' | 'ИИ' | 'ПД' | 'РД'

export const projectPhaseByTomType: Record<TomType, ProjectPhase> = {
  ИРД: 'Сбор исходных данных',
  РД: 'Рабочая документация',
  ПД: 'Проектная документация',
  ИИ: 'Инженерные изыскания',
}

export const determineTomType = (selectedProjectPhase: ProjectPhase): TomType => {
  switch (selectedProjectPhase) {
    case 'Сбор исходных данных':
      return 'ИРД'
    case 'Инженерные изыскания':
      return 'ИИ'
    case 'Проектная документация':
      return 'ПД'
    case 'Рабочая документация':
      return 'РД'
  }
}

export const isRdTom = (tom: Tom): tom is TomRd => {
  return (tom as TomRd)?.objectId !== undefined
}
export const isPdTom = (tom: Tom): tom is TomPd => {
  return (tom as TomPd)?.pdId !== undefined
}
export const isIiTom = (tom: Tom): tom is TomIi => {
  return (tom as TomIi)?.iiId !== undefined
}
export const isIrdTom = (tom: Tom): tom is TomIrd => {
  return (tom as TomIrd)?.irdId !== undefined
}
